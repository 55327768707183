@use 'src/styles' as *;
@use 'src/assets/styles/globalMixins' as globalMixins;

$g-padding-y: 6px; //for grid items
$g-padding-x: 10px; //for grid items
$g-table-spacing-y: 10px;
$g-item-font-size: $font_size_l;

@mixin complete-container {
  display: flex;
  align-items: center;
  gap: 5px;
  .progress-bar {
    height: 10px;
    width: 100%;
    box-sizing: content-box;
    border: 1px solid color(shade_4);
    flex: 1;
    .fill {
      height: 100%;
      width: 0;
      background-color: color(color_accent);
      border: 1px solid color(shade_4);
      margin: -1px;

      &.overcommitment {
        background-color: color(color_red_light);
      }
    }
  }
  .complete-percent-text {
    width: 45px;
    text-align: right;
    font-weight: 700;
    font-size: $font_size_m;
    white-space: nowrap;

    &.negative {
      color: color(color_red_negative);
    }

    &.overcommitment {
      color: color(color_red_negative);
    }
  }
}

@mixin header-row-item() {
  background: color(shade_7);
  color: color(shade_2);
  padding: 11px 12px;
  font-weight: 600;
  font-size: $font_size_l;
}

@mixin general-grid-table() {
  .table-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    .scrollbar-wrapper {
      flex: 1;
    }
    .grid-table {
      display: grid;
      .grid-row {
        display: contents;
      }
      .item {
        display: flex;
        align-items: center;
      }
      .header-row {
        .item {
          @include header-row-item;
        }
      }
      .data-row {
        .item {
          color: color(color_primary);
          font-size: $font_size_l;
          padding: 8px 12px;
        }
      }
    }
  }
}

@mixin grid-table-dynamic-table(
  $padding-y: 6px,
  $padding-x: 10px,
  $item-font-size: $font_size_l,
  $item-color: color(shade_1)
) {
  .grid-row .item:last-child {
    padding-right: 25px;
  }

  .grid-row {
    display: grid;
    .item {
      @include item-styles-dynamic-table(
        $padding-y,
        $padding-x,
        $item-font-size,
        $item-color
      );
    }

    &.header-row .item:last-child {
      padding-right: 21px;
    }
  }

  .header-row .item {
    background: color(shade_6);
    color: color(shade_2);
    font-weight: 600;
    font-size: $font_size_m;
    line-height: $font_size_xl;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
  }

  &.totals {
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    font-weight: 700;
    color: color(color_primary);
    font-size: $font_size_xl;
    border-top: 1px solid color(shade_5);
  }
}

@mixin item-styles-dynamic-table(
  $padding-y: 6px,
  $padding-x: 10px,
  $item-font-size: $font_size_l,
  $item-color: color(shade_1)
) {
  margin: auto 0;
  cursor: default;
  min-width: 0;
  padding: $padding-y $padding-x;
  color: $item-color;
  font-size: $item-font-size;

  @media screen and (max-width: 1250px) {
    padding: calc(#{$padding-y} / 2) calc(#{$padding-x} / 2);
  }

  &.negative {
    @include globalMixins.negative(2px);
  }
}
