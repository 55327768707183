
.icon-graph:before { content: '\e800'; } /* '' */
.icon-spreadsheet:before { content: '\e801'; } /* '' */
.icon-rename:before { content: '\e802'; } /* '' */
.icon-document:before { content: '\e803'; } /* '' */
.icon-participants:before { content: '\e804'; } /* '' */
.icon-download:before { content: '\e805'; } /* '' */
.icon-delete:before { content: '\e806'; } /* '' */
.icon-view:before { content: '\e807'; } /* '' */
.icon-pending-proposals:before { content: '\e808'; } /* '' */
.icon-notification-bell-full:before { content: '\e809'; } /* '' */
.icon-chat-skeleton:before { content: '\e80a'; } /* '' */
.icon-logo-hex-title:before { content: '\e80b'; } /* '' */
.icon-activities:before { content: '\e80c'; } /* '' */
.icon-send-invite:before { content: '\e80d'; } /* '' */
.icon-send-multiple-invite:before { content: '\e80e'; } /* '' */
.icon-cashflow:before { content: '\e80f'; } /* '' */
.icon-print-report-2:before { content: '\e810'; } /* '' */
.icon-triangle-dots:before { content: '\e811'; } /* '' */
.icon-folded-file-plus:before { content: '\e812'; } /* '' */
.icon-download-template:before { content: '\e813'; } /* '' */
.icon-gantt-chart:before { content: '\e816'; } /* '' */
.icon-scheduled-visits:before { content: '\e818'; } /* '' */
.icon-gavel:before { content: '\e819'; } /* '' */
.icon-signature:before { content: '\e81b'; } /* '' */
.icon-reporting:before { content: '\e81c'; } /* '' */
.icon-edit:before { content: '\e81e'; } /* '' */
.icon-info:before { content: '\e822'; } /* '' */
.icon-padlock-unlocked:before { content: '\e826'; } /* '' */
.icon-padlock-locked:before { content: '\e828'; } /* '' */
.icon-tasks:before { content: '\e82f'; } /* '' */
.icon-warning:before { content: '\e830'; } /* '' */
.icon-in-progress:before { content: '\e831'; } /* '' */
.icon-binoculars:before { content: '\e832'; } /* '' */
.icon-attachments:before { content: '\e833'; } /* '' */
.icon-message-outline:before { content: '\e838'; } /* '' */
.icon-attachments-chain:before { content: '\e839'; } /* '' */
.icon-info-filled-1:before { content: '\e83a'; } /* '' */
.icon-arrow-right-light:before { content: '\e83f'; } /* '' */
.icon-participants-circle-bg:before { content: '\e846'; } /* '' */
.icon-manage:before { content: '\e848'; } /* '' */
.icon-budget-cashflow:before { content: '\e849'; } /* '' */
.icon-heart:before { content: '\e84a'; } /* '' */
.icon-service-team:before { content: '\e84b'; } /* '' */
.icon-commitments:before { content: '\e84c'; } /* '' */
.icon-not_paid:before { content: '\e84e'; } /* '' */
.icon-paid:before { content: '\e84f'; } /* '' */
.icon-approved:before { content: '\e850'; } /* '' */
.icon-pending:before { content: '\e852'; } /* '' */
.icon-draft:before { content: '\e853'; } /* '' */
.icon-review:before { content: '\e854'; } /* '' */
.icon-info-filled:before { content: '\e857'; } /* '' */
.icon-copy-move-two-paper-sheets:before { content: '\e85c'; } /* '' */
.icon-new-message-plus-chat:before { content: '\e85f'; } /* '' */
.icon-at-sign:before { content: '\e860'; } /* '' */
.icon-new-message-plus-chat-unfilled:before { content: '\e861'; } /* '' */
.icon-drive:before { content: '\e863'; } /* '' */
.icon-projects:before { content: '\e865'; } /* '' */
.icon-service-providers:before { content: '\e866'; } /* '' */
.icon-bell:before { content: '\e867'; } /* '' */
.icon-messages:before { content: '\e868'; } /* '' */
.icon-question-mark:before { content: '\e869'; } /* '' */
.icon-account:before { content: '\e86a'; } /* '' */
.icon-logout:before { content: '\e86b'; } /* '' */
.icon-team:before { content: '\e86d'; } /* '' */
.icon-lightning-rectangular:before { content: '\e86e'; } /* '' */
.icon-properties:before { content: '\e86f'; } /* '' */
.icon-templates:before { content: '\e870'; } /* '' */
.icon-download_budget:before { content: '\e871'; } /* '' */
.icon-lightning-filled:before { content: '\e873'; } /* '' */
.icon-dropdown-down-border:before { content: '\e88a'; } /* '' */
.icon-print-report:before { content: '\e88c'; } /* '' */
.icon-bordered-x:before { content: '\e88d'; } /* '' */
.icon-contact-brief:before { content: '\e8ad'; } /* '' */
.icon-file-x:before { content: '\e8af'; } /* '' */
.icon-duplicate:before { content: '\e8b1'; } /* '' */
.icon-trash-x:before { content: '\e8b2'; } /* '' */
.icon-contacts:before { content: '\e8b3'; } /* '' */
.icon-circle-question-mark:before { content: '\e8b4'; } /* '' */
.icon-copy-two-paper-sheets:before { content: '\e8b8'; } /* '' */
.icon-move-two-paper-sheets:before { content: '\e8b9'; } /* '' */
.icon-remove-circle-x:before { content: '\e8ba'; } /* '' */
.icon-draggable:before { content: '\e8bd'; } /* '' */
.icon-attachment-square:before { content: '\e8bf'; } /* '' */
.icon-box-checked:before { content: '\e8c0'; } /* '' */
.icon-participants-circle:before { content: '\e8c2'; } /* '' */
.icon-commitment:before { content: '\e8c3'; } /* '' */
.icon-pie-chart:before { content: '\e8c4'; } /* '' */
.icon-upload-square:before { content: '\e8de'; } /* '' */
.icon-underline:before { content: '\e8df'; } /* '' */
.icon-link:before { content: '\e8e0'; } /* '' */
.icon-italic:before { content: '\e8e1'; } /* '' */
.icon-hashtag:before { content: '\e8e2'; } /* '' */
.icon-bold:before { content: '\e8e3'; } /* '' */
.icon-expand-square:before { content: '\e8e7'; } /* '' */
.icon-collapse-square:before { content: '\e8e8'; } /* '' */
.icon-cashflow-modern:before { content: '\e8ee'; } /* '' */
.icon-tools:before { content: '\e8ef'; } /* '' */
.icon-budget-estimation:before { content: '\e8f0'; } /* '' */
.icon-cloud-circle-dots:before { content: '\e8f1'; } /* '' */
.icon-approvals:before { content: '\e8f2'; } /* '' */
.icon-printer-in-dot:before { content: '\e8f3'; } /* '' */
.icon-markup:before { content: '\e8f7'; } /* '' */
.icon-unlink:before { content: '\e900'; } /* '' */
.icon-share-external:before { content: '\e976'; } /* '' */
.icon-logo-box-hex:before { content: '\e9a9'; } /* '' */
.icon-arrow-round-left:before { content: '\e9e9'; } /* '' */
.icon-arrow-round-right:before { content: '\e9ea'; } /* '' */
.icon-arrow-round-right-filled:before { content: '\e9eb'; } /* '' */
.icon-contained-question-mark:before { content: '\f01a'; } /* '' */
.icon-plus-box:before { content: '\f01b'; } /* '' */
.icon-calendar-box:before { content: '\f01c'; } /* '' */
.icon-minus-box:before { content: '\f01d'; } /* '' */
.icon-folded-file-upload:before { content: '\f01f'; } /* '' */
.icon-options-inverted:before { content: '\f020'; } /* '' */
.icon-alert-exclamation:before { content: '\f021'; } /* '' */
.icon-rounded-triangle:before { content: '\f022'; } /* '' */
.icon-download-folder-arrow:before { content: '\f023'; } /* '' */
.icon-folder-x:before { content: '\f024'; } /* '' */
.icon-add-block-square:before { content: '\f025'; } /* '' */
.icon-copy-block:before { content: '\f026'; } /* '' */
.icon-folded-spreadsheet:before { content: '\f027'; } /* '' */
.icon-share-unfilled-block:before { content: '\f028'; } /* '' */
.icon-folded-file-download:before { content: '\f029'; } /* '' */
.icon-pencil-block:before { content: '\f030'; } /* '' */
.icon-move-block:before { content: '\f031'; } /* '' */
.icon-share-filled-block:before { content: '\f033'; } /* '' */
.icon-folded-word-doc:before { content: '\f034'; } /* '' */
